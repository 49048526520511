<template>
  <v-container class="pb-1 pa-0">
    <v-btn color="success" depressed size="small" @click="getPDF" :loading="loading">
      <v-icon left>mdi-printer</v-icon> Aditivo Nº {{ nrAditivo }}
    </v-btn>
  </v-container>
</template>
<script>
import api from "../../../http";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import UtilsFunc from "../../../service/utilsFunc";
import UtilsPDF from '../../../service/utilsPDF';
import logo from "../../../assets/logo.png";
import gptw from "../../../assets/GPTW_2023-2024.png"
import ona from "../../../assets/ONA_Nivel_2.png"

const { withCRUDUtils } = UtilsFunc
export default {
  name: "Orcamento",
  components: {
  },
  data: () => withCRUDUtils({
    prorrogacao: [],
    budget: [],
    taxa: [],
    equipamentos: [],
    loading: false,
    totalBudget: null,
    totalFee: null,
    totalEquipment: null,
  }),
  props: {
    selectedBudgets: {
      type: Number,
    },
    nrAditivo: Number,
    username: String
  },
  methods: {
    async getPDF() {
      this.loading = true;
      this.$toast.success(
        `O documento está sendo preparado. Isso pode levar alguns instantes. Aguarde, por favor.`
      );
      try {
        await Promise.all([
          this.getOrcamento(),
          this.getProrrogacao(),
          this.getTaxa(),
          this.getEquipamentos()
        ]);
        await this.generatePDF()
        this.loading = false;
      } catch (error) {
        this.$toast.error("Erro ao carregar os dados (L-56):", error);
      }
    },
    async download(dataurl, filename) {
      const httpsurl = !dataurl.includes("https") ? dataurl.replace("http", "https") : dataurl;
      const res = await fetch(httpsurl);
      const buffer = await res.blob();
      const url = URL.createObjectURL(buffer);
      const link = document.createElement("a");
      link.download = filename;
      link.href = url;
      link.click();
    },
    drawHeader(doc) {
      const nrAditivo = this.nrAditivo || 'Indisponível';
      try {
        doc.addImage(logo, 10, 8, 28, 28);
        doc.addImage(ona, 160, 10, 15, 15);
        doc.addImage(gptw, 185, 10, 15, 15);
      } catch (error) {
        this.$toast.warning("Falha ao adicionar a imagem ao PDF (L-76):", error);
      }

      const data = this.budget[0];
      const aditivo = this.prorrogacao[0];

      const lineHeight = 6
      const xFixedPosition = 10;
      let initialYPosition = 45;
      let yPosition = 45;

      doc.setFont("helvetica", "normal");
      doc.setDrawColor(57, 106, 159);
      doc.setLineWidth(1);
      doc.line(5, 5, 205, 5);

      doc.setLineWidth(0.5); // largura da borda
      doc.roundedRect(5, 40, 200, 25, 3, 3, "S");

      doc.setDrawColor(0, 0, 0);
      doc.setFont("helvetica", "bold");
      doc.setFontSize(18); // Tamanho da fonte, ajuste conforme necessário
      doc.text("São Luís Home Care", 105, 15, { align: "center" });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(16);
      doc.text(`Aditivo nº ${nrAditivo} do Orçamento nº ${data?.id}`, 105, 23, { align: "center" });

      moment.locale("pt-br"); // garantir que seja horario de brasília independente do dispositivo

      const currentDate = new Date();
      const hour = moment(currentDate).format("HH:mm");

      doc.setFontSize(8);
      const marginRight = 5; // Defina a margem desejada do lado direito (5px)
      const pageWidth = doc.internal.pageSize.getWidth(); // Obtenha a largura total da página
      const textWidth = doc.getTextWidth(`Gerado por: ${this.username}`); // Calcule a largura do texto "Gerado por: username"
      const xPosition = pageWidth - textWidth - marginRight; // Calcule a posição x para alinhar o texto à direita
      doc.text(`Gerado por: ${this.username}`, xPosition, 28); 
      doc.text("Data e Hora da Impressão:", pageWidth - doc.getTextWidth("Data e Hora da Impressão:") - marginRight, 32);
      doc.text(`${UtilsPDF.formatDate(currentDate)} às ${hour}`, pageWidth - doc.getTextWidth(`${UtilsPDF.formatDate(currentDate)} às ${hour}`) - marginRight, 36);

      if (data && data?.data_inicio && data?.data_fim) {
        doc.setFontSize(11);
        doc.text(`Período de: ${(UtilsPDF.formatDate(aditivo?.data_aditivo)) ? (UtilsPDF.formatDate(aditivo?.data_aditivo)) : (UtilsPDF.formatDate(data.data_inicio))} até: ${UtilsPDF.formatDate(data.data_fim)}`, 71, 32);
      }

      doc.setFontSize(12);
      doc.text(`Paciente: ${data && data.paciente ? data.paciente : ''}`, xFixedPosition, initialYPosition);

      yPosition = initialYPosition + lineHeight
      const dataNascimento = data && data.data_nascimento ? `Data Nascimento: ${UtilsPDF.formatDate(data.data_nascimento)}` : 'Data Nascimento: ';
      doc.text(dataNascimento, 200, initialYPosition, { align: "right" });

      doc.text(`Operadora: ${data && data.convenio ? data.convenio : ''}`, xFixedPosition, yPosition);
      doc.text(`Nº da Carteira: ${data && data.nr_carteirinha ? data.nr_carteirinha : ''}`, 200, yPosition, { align: "right", });

      yPosition += lineHeight
      doc.text(`Modalidade: ${data && data.modalidade ? data.modalidade : ''}`, xFixedPosition, yPosition);

      const maxWidth = 200;
      const spaceBetweenTexts = 10; // Espaço entre modalidadeText e CID

      const modalidadeText = `Modalidade: ${data && data.modalidade ? data.modalidade : ''}`;
      const modalidadeTextWidth = doc.getTextWidth(modalidadeText);

      if (data && data.cid && data.cid_descricao) {
        const cid = data.cid || '';
        const cidDescricao = data.cid_descricao || '';
        const fullText = `CID: ${cid} - ${cidDescricao}`;

        const renderTextLine = (doc, text, x, y, align = 'left') => {
          doc.setFont("helvetica", "normal");
          doc.text(text, x, y, { align: align });
        };

        const remainingWidth = maxWidth - modalidadeTextWidth - spaceBetweenTexts;
        const splittedText = doc.splitTextToSize(fullText, remainingWidth);

        splittedText.forEach((line, i) => {
          const xPos = i === 0 ? 200 : 10;
          const align = i === 0 ? 'right' : 'left';
          const yPos = yPosition + (lineHeight * i);
          renderTextLine(doc, line, xPos, yPos, align);
        });

        yPosition += (lineHeight * splittedText.length);

      } else {
        doc.setFont("helvetica", "normal");
        doc.text(`CID: `, 200, yPosition, { align: "right" });
      }

      if (doc.internal.getNumberOfPages() === 1) {
        yPosition = 75
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Segue o aditivo detalhado conforme solicitação.`, 8, yPosition);

        yPosition = 82
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text(`Ficamos no aguardo da autorização para darmos início aos serviços abaixo.`, 8, yPosition);
      }
    },
    async generatePDF() {
      const doc = new jsPDF({ compress: true });
      this.drawHeader(doc);

      let xPosition = 10;
      let yPosition = 93;
      const pageWidth = 210;
      const maxHeight = 270;
      const lineHeight = 6;

      const data = this.budget[0];
      const prorrogacao = this.prorrogacao;

      const startDate = new Date(data?.data_inicio);
      const endDate = new Date(data?.data_fim);
      const numberOfDays = (endDate - startDate) / (1000 * 60 * 60 * 24) + 1;
      const operadora = data && data.convenio ? data.convenio : '';

      doc.setFontSize(16);
      doc.setFont("helvetica", "bold");
      const textWidth = doc.getTextWidth("Resumo do Aditivo");
      const xCenteredPosition = pageWidth / 2 - textWidth / 2;
      doc.text("Resumo do Aditivo", xCenteredPosition, yPosition);
      doc.setFontSize(12);
      doc.setFont("helvetica", "normal");
      yPosition += lineHeight * 2;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
      if (prorrogacao && prorrogacao.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Procedimento(s)", xPosition, yPosition);
        yPosition += lineHeight;

        const groupByProcedure = (procedure) => {
          const groupedBy = {};

          procedure.forEach(item => {
            const discount = item.desconto ? parseFloat(item.desconto) / 100 : 0;
            const addition = item.acrescimo ? parseFloat(item.acrescimo) / 100 : 0;
            const adjustedUnitValue = (item.cobranca !== '0' && item.valor_cobranca)
              ? parseFloat(item.valor_cobranca) * (1 + addition - discount)
              : 0;

            const qtdRealizada = item.especial === true ? item.qtdEspecial : item.quantidade;
            const valorTotal = adjustedUnitValue * qtdRealizada;
            const nomeProcedimento = (item?.procedimento.nomecomercial  && item.procedimento.nomecomercial.trim() !== "") ? `${item.procedimento.procedimento} - (${item.procedimento.nomecomercial})` : item.procedimento.procedimento;
            // Chave única para agrupamento
            const key = `${item.procedimento.codigo}-${item.frequencia?.nome || ''}-${adjustedUnitValue}-${item.desconto || '--'}-${item.acrescimo || '--'}`;

            if (!groupedBy[key]) {
              groupedBy[key] = {
                'Código': item.procedimento.codigo,
                'Procedimento': nomeProcedimento,
                'Frequência': item.especial === true ? '' : item.frequencia.nome,
                'Qtd.': 0,
                'Valor Unitário': UtilsPDF.formatToBRL(parseFloat(item.valor_cobranca) || 0),
                'Des %.': item.cobranca === '0' ? '100.0' : (item.desconto || '--'),
                'Acres %.': (item.cobranca === '0' || item.desconto === '100') ? '--' : (item.acrescimo || '--'),
                'Valor Total': 0
              };
            }

            groupedBy[key]['Qtd.'] += qtdRealizada;
            groupedBy[key]['Valor Total'] += valorTotal;
          });

          // Formatar o valor total agrupado para BRL
          Object.keys(groupedBy).forEach(key => {
            groupedBy[key]['Valor Total'] = UtilsPDF.formatToBRL(groupedBy[key]['Valor Total']);
          });

          return Object.values(groupedBy);
        };

        const table = groupByProcedure(prorrogacao);

        const columns = ['Código', 'Procedimento', 'Frequência', 'Qtd.', 'Valor Unitário', 'Des %.', 'Acres %.', 'Valor Total'];
        const rows = [];
        table.forEach(item => {
          const row = [];
          columns.forEach(column => {
            row.push(item[column]);
          });
          rows.push(row);
        });

        doc.autoTable({
          startY: yPosition,
          head: [columns],
          body: rows,
          styles: { lineWidth: 0.1 },
          margin: { left: 8, right: 8 },
          columnStyles: {
            0: { halign: 'center' },
            2: { cellWidth: 30 },
            4: { cellWidth: 30 },
            7: { cellWidth: 28 }
          },
        });

        yPosition = doc.lastAutoTable.finalY + lineHeight;

        // Cálculo do Valor Total dos Procedimentos
        const totalBudget = table.reduce((acc, item) => {
          const valorTotal = UtilsPDF.parseFormattedCurrency(item['Valor Total']) || 0;
          return acc + valorTotal;
        }, 0);

        this.totalBudget = totalBudget;
        yPosition += lineHeight;
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Procedimento(s): ${UtilsPDF.formatToBRL2(totalBudget)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      }

      const taxas = this.taxa;
      if (taxas && taxas.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Taxa(s)", xPosition, yPosition);
        yPosition += lineHeight;
        let totalFee = 0;

        const columns = [
          'Código',
          'Taxa',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        taxas.forEach(taxa => {
          if (taxa.ativo && taxa.aditivo_taxa) {
            const valorCobrancaTaxa = taxa?.valor_cobranca_taxa || 0;
            const valorCobrancaFinalTaxa = taxa?.valor_cobranca_final_taxa || 0;
            const nomeTaxa = (taxa?.taxa?.nomecomercial  && taxa.taxa.nomecomercial.trim() !== "") ? `${taxa?.taxa?.taxa?.nome} - (${taxa?.taxa?.nomecomercial})` : taxa?.taxa?.taxa?.nome;

            const row = {
              'Código': taxa?.taxa?.codigo || '',
              'Taxa': nomeTaxa || '',
              'Qtd.': String(taxa?.quantidade_taxa || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobrancaTaxa),
              'Des %.': taxa?.desconto_taxa || '--',
              'Acres %.': taxa?.acrescimo_taxa || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinalTaxa)
            };

            rows.push(Object.values(row));

            this.totalFee += valorCobrancaFinalTaxa;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-344):", error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total da(s) Taxa(s): ${UtilsPDF.formatToBRL2(totalFee)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de taxa encontrado');
      }

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);

      const equipamentos = this.equipamentos;
      if (equipamentos && equipamentos.length > 0) {
        doc.setFontSize(14);
        doc.setFont("helvetica", "bold");
        doc.text("Equipamento(s)", xPosition, yPosition);
        yPosition += lineHeight;
        let totalEquipment = 0;

        const columns = [
          'Código',
          'Equipamento',
          'Qtd.',
          'Valor Unitário',
          'Des %.',
          'Acres %.',
          'Valor Total'
        ];
        const rows = [];

        equipamentos.forEach(equipamento => {
          if (equipamento.ativo && equipamento.aditivo_equipamento) {
            const valorCobranca = equipamento?.valor_cobranca_equipamento || 0;
            const valorCobrancaFinal = equipamento?.valor_cobranca_final_equipamento || 0;
            const nomeEquipamento = (equipamento?.equipamento?.nomecomercial &&  equipamento.equipamento.nomecomercial.trim() !== "") ? `${equipamento?.equipamento?.equipamento?.nome} - (${equipamento?.equipamento?.nomecomercial})` : equipamento?.equipamento?.equipamento?.nome;

            const row = {
              'Código': equipamento?.equipamento?.codigo || '',
              'Equipamento': nomeEquipamento|| '',
              'Qtd.': String(equipamento?.quantidade_equipamento || ''),
              'Valor Unitário': UtilsPDF.formatToBRL2(valorCobranca),
              'Des %.': equipamento?.desconto_equipamento || '--',
              'Acres %.': equipamento?.acrescimo_equipamento || '--',
              'Valor Total': UtilsPDF.formatToBRL2(valorCobrancaFinal)
            };

            rows.push(Object.values(row));

            this.totalEquipment += valorCobrancaFinal;
          }
        });

        try {
          doc.autoTable({
            startY: yPosition,
            head: [columns],
            body: rows,
            styles: { lineWidth: 0.1 },
            margin: { left: 8, right: 8 }
          });
        } catch (error) {
          this.$toast.error("Erro ao carregar os dados (L-409):", error);
          // console.error(error);
        }

        yPosition = doc.lastAutoTable.finalY + lineHeight;
        yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);
        doc.setFontSize(10);
        doc.setFont("helvetica", "bold");
        doc.text(`Valor Total do(s) Equipamento(s): ${UtilsPDF.formatToBRL2(totalEquipment)}`, 200, yPosition, { align: "right" });
        yPosition += lineHeight * 2;
      } else {
        // console.log('Nenhum dado de equipamento encontrado');
      }

      const totalOrcamento = this.totalBudget + this.totalFee + this.totalEquipment
      const dailyAverage = totalOrcamento / numberOfDays;

      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);

      const text = 'do Aditivo'
      UtilsPDF.summary(doc, yPosition, lineHeight, dailyAverage, totalOrcamento, text)

      yPosition += lineHeight;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, 250, 80, this.drawHeader);

      yPosition += lineHeight + 2;
      yPosition = UtilsPDF.checkAndAddPage(doc, yPosition, lineHeight, maxHeight, 80, this.drawHeader);

      UtilsPDF.addSignatureBlock(doc, operadora, lineHeight, xPosition, 245);

      UtilsPDF.addPageNumbers(doc);

      const aditivo = this.prorrogacao[0];
      const data_inicio = aditivo?.data_aditivo ? aditivo?.data_aditivo : data?.data_inicio;
      const data_fim = this.budget[0]?.data_fim;
      const period = data_inicio && data_fim ? `${UtilsPDF.formatDateToFilename(data_inicio)}_ate_${UtilsPDF.formatDateToFilename(data_fim)}` : '';

      const filename = `Aditivo_Nº_${this.nrAditivo}_do_Orçamento_Nº_${data?.id}__Período_${period}.pdf`;

      const pdfBuffer = doc.output('arraybuffer');
      const pdfBlob = new Blob([pdfBuffer], { type: "application/pdf" });
      const formData = new FormData();
      formData.append("file", pdfBlob, filename);
      formData.append("filename", filename);

      try {
        const { data: signedForm } = await api.post('multi_empresas/sign-pdf/', formData)
        const signedPdfUrl = signedForm.path
        this.download(signedPdfUrl, filename)
        this.$toast.success('O download do relatório foi realizado com sucesso!');
      } catch (error) {
        this.$toast.error('Ocorreu um erro ao tentar gerar o PDF (L-461).', error.response ? error.response.data : error.message);
      }
    },
    async getProrrogacao() {
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedBudgets}`);
        this.prorrogacao = data.filter(item => item.aditivo === true && item.ativo === true && item.nr_aditivo === this.nrAditivo);
        // console.log('Prorrogacao --> ', this.prorrogacao)
      } catch (error) {
        this.$toast.warning('Erro ao buscar os dados da Prorrogação (L-469):', error);
      }
    },
    async getOrcamento() {
      try {
        const { data } = await api.get(`atendimentos/relatorio-orcamento/?orcamento=${this.selectedBudgets}`);
        this.budget = data;
        // console.log('getOrcamento --> ', this.budget)
      } catch (error) {
        this.$toast.warning('Erro ao buscar os dados do Orçamento (L-478):', error);
      }
    },
    async getTaxa() {
      try {
        const nraditivo = this.nrAditivo
        const { data } = await api.get(`atendimentos/taxas/?orcamento=${this.selectedBudgets}`);
        this.taxa = data.filter(item => item.ativo && item.aditivo_taxa && item.nr_aditivo === nraditivo);
      } catch (error) {
        this.$toast.warning('Erro ao buscar os dados das Taxas (L-487):', error);
      }
    },
    async getEquipamentos() {
      try {
        const nraditivo = this.nrAditivo
        const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedBudgets}`);
        this.equipamentos = data.filter(item => item.ativo && item.aditivo_equipamento && item.nr_aditivo === nraditivo);
        // console.log('Equiopamento --> ', this.equipamentos)
      } catch (error) {
        this.$toast.warning('Erro ao buscar os dados dos Equipamentos (L-497):', error);
      }
    }
  },
};
</script>